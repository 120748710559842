import { useDeleteNote, useNoteForDate, usePostNote } from '@hooks/useNotes'
import { ModalForm } from 'components/notifications'
import { FC, useEffect, useState } from 'react'
import { toast } from '@lib/toasts'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { useTranslation } from 'react-i18next'
import { useRenderLocaleWeekday } from '@hooks/useDateTimeWithLocale'

interface Props {
  date: DateTime
  onCancel: () => void
  onSave: () => void
  saveButtonText?: string
  children?: React.ReactNode
}

export const NoteForm: FC<Props> = ({
  date,
  onCancel,
  onSave,
  saveButtonText,
  children,
}) => {
  const { t } = useTranslation()
  const userId = useUserId()
  const { note } = useNoteForDate(date, { userId })
  const noteBody = note?.body ?? '' // nullish coallesence ensures that text area remains "controlled"
  const postNoteMutation = usePostNote({ userId })
  const deleteNoteMutation = useDeleteNote({ userId })
  const [formContent, setFormContent] = useState(noteBody)
  const day = useRenderLocaleWeekday()(date)

  useEffect(() => {
    setFormContent(noteBody)
  }, [noteBody])

  const postNote = async () => {
    try {
      await postNoteMutation.mutateAsync({ date, note: formContent })

      toast({
        content: t('features.timeLogging.successfullyAddedNote', { day }),
        title: t('features.timeLogging.noteAdded'),
        variant: 'success',
      })

      onSave()
    } catch (e) {
      toast({
        content: t('features.timeLogging.unableToAddNote', { day }),
        title: t('features.timeLogging.errorAddingNote'),
        variant: 'error',
      })
    }
  }

  const deleteNote = async () => {
    try {
      if (note) {
        await deleteNoteMutation.mutateAsync({
          date,
          note: formContent,
        })

        toast({
          content: t('features.timeLogging.successfullyDeletedNote', { day }),
          title: t('features.timeLogging.noteDeleted'),
          variant: 'success',
        })
      }

      onSave()
    } catch {
      toast({
        content: t('features.timeLogging.unableToDeleteNote', { day }),
        title: t('features.timeLogging.errorDeletingNote'),
        variant: 'error',
      })
    }
  }

  const handleSave = () => {
    const operation = formContent.trim() === '' ? deleteNote : postNote
    void operation()
  }

  const fields = (
    <>
      {children}
      <textarea
        autoFocus
        id="note"
        className="h-32 text-sm rounded border-neutral-300 bg-neutral-50 focus:ring-0 focus:ring-offset-0 focus:ring-success-900 focus:border-neutral-300"
        placeholder={t('features.timeLogging.addANote...')}
        value={formContent}
        onChange={(event) => setFormContent(event.currentTarget.value)}
      />
      <span className="text-xs italic text-neutral-400">
        {t('features.timeLogging.noteWillBeVisibleToApproversAndAdmins')}
      </span>
    </>
  )

  return (
    <ModalForm
      className="max-w-[400px]"
      fields={fields}
      isSaving={postNoteMutation.isLoading}
      onSave={() => void handleSave()}
      onCancel={onCancel}
      saveButtonText={saveButtonText}
    />
  )
}
