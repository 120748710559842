import { ScrollableTable, Td, Th, Tr } from 'components/tables'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnFiltersState,
  ColumnDef,
} from '@tanstack/react-table'
import { FC, memo, useState } from 'react'
import { SearchQuery, ValuePicker } from 'components/tables/column-filters'
import { useDateTimeToLocaleString } from 'hooks/useDateTimeWithLocale'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { useAdminManagedGroups } from '@hooks/useAdminManagedGroups'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
}

export const Table: FC<Props> = memo(({ className }) => {
  const queryResult = useAdminManagedGroups()
  const { t } = useTranslation()
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'group-name',
      desc: false,
    },
  ])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const dateTimeToLocale = useDateTimeToLocaleString()
  const renderDateTime = (date: DateTime) =>
    dateTimeToLocale(DateTime.DATE_SHORT)(date)

  const columns = [
    {
      id: 'group-name',
      header: t('features.adminManagedGroups.groupName'),
      accessorKey: 'name',
      filterFn: 'includesString',
      cell: ({ row }) => {
        return (
          <Link className="font-semibold" to={row.original.id.toString()}>
            {row.original.name}
          </Link>
        )
      },
    } as ColumnDef<AdminManagedGroup, string>,
    {
      id: 'created-by',
      header: t('features.adminManagedGroups.createdBy'),
      accessorKey: 'creator.name',
      filterFn: (row, _columnId, filterValue: Set<number>) => {
        if (filterValue.size === 0) return true

        return filterValue.has(row.original.creator.id)
      },
    } as ColumnDef<AdminManagedGroup, AdminManagedGroup['creator']['name']>,
    {
      id: 'created-at',
      header: t('features.adminManagedGroups.dateCreated'),
      accessorKey: 'createdAt',
      cell: ({ getValue }) => renderDateTime(getValue()),
    } as ColumnDef<AdminManagedGroup, DateTime>,
    {
      id: 'updated-at',
      header: t('features.adminManagedGroups.lastModified'),
      accessorKey: 'updatedAt',
      cell: ({ getValue }) => renderDateTime(getValue()),
    } as ColumnDef<AdminManagedGroup, DateTime>,
    {
      id: 'approval-groups',
      header: t('common.approvalGroups'),
      accessorFn: (row) => row.approvalGroups.map((ag) => ag.name).join(', '),
      filterFn: 'includesString',
    } as ColumnDef<AdminManagedGroup, string[]>,
    {
      id: 'employees',
      header: t('common.employees'),
      accessorFn: (row) => row.employees.map((e) => e.name).join(', '),
      filterFn: 'includesString',
    } as ColumnDef<AdminManagedGroup, string[]>,
  ]

  const table = useReactTable({
    data: queryResult.data ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })

  return (
    <ScrollableTable
      rootClassName={className}
      header={table.getHeaderGroups().map((headerGroup) => (
        <Tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            return (
              <Th
                key={header.id}
                clickable={header.column.getCanSort()}
                label={flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
                onClick={header.column.getToggleSortingHandler()}
                sortDir={header.column.getIsSorted()}
              >
                {header.id === 'group-name' && (
                  <SearchQuery
                    columnNameTranslationKey="features.adminManagedGroups.groupName"
                    onChange={(value) => header.column.setFilterValue(value)}
                    query={header.column.getFilterValue() as string}
                  />
                )}
                {header.id === 'created-by' && (
                  <ValuePicker
                    accessor={(v) => v.id}
                    columnNameTranslationKey="features.adminManagedGroups.createdBy"
                    onChange={(value) => header.column.setFilterValue(value)}
                    renderLabel={(value) => value.name}
                    selected={
                      (header.column.getFilterValue() ??
                        new Set()) as Set<number>
                    }
                    values={table
                      .getCoreRowModel()
                      .rows.reduce<AdminManagedGroup['creator'][]>(
                        (acc, { original: { creator } }) => {
                          if (acc.find((c) => c.id === creator.id)) return acc

                          return [...acc, creator]
                        },
                        [],
                      )}
                  />
                )}
                {header.id === 'approval-groups' && (
                  <SearchQuery
                    columnNameTranslationKey="common.approvalGroups"
                    onChange={(value) => header.column.setFilterValue(value)}
                    query={header.column.getFilterValue() as string}
                  />
                )}
                {header.id === 'employees' && (
                  <SearchQuery
                    columnNameTranslationKey="common.employees"
                    onChange={(value) => header.column.setFilterValue(value)}
                    query={header.column.getFilterValue() as string}
                  />
                )}
              </Th>
            )
          })}
        </Tr>
      ))}
      body={table.getRowModel().rows.map((row) => {
        return (
          <Tr key={row.id}>
            {row.getVisibleCells().map((cell) => {
              return (
                <Td key={cell.id} className="p-2">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              )
            })}
          </Tr>
        )
      })}
    />
  )
})

Table.displayName = 'Table'
