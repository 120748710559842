import { TaskTotal } from '@features/time-logging/components/entries-table/cells/TaskTotal'
import {
  faCalculator,
  faSlashForward,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { Tooltip } from '@lib/tooltip'
import { Spinner } from 'components/loaders'
import {
  forwardRef,
  ReactNode,
  useId,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { twJoin } from 'tailwind-merge'
import { TaskEstimateMode } from 'types'
import { GridCell } from '../GridCell'
import { Bars } from './Bars'
import { renderDaysHoursMinutes } from './utils/renderDuration'

interface Props {
  card: (options: { clearFocus: () => void }) => ReactNode
  estimated?: number // seconds
  estimateMode: TaskEstimateMode
  logged?: number // seconds
  rowIndex: number
  showLoading: boolean
  taskTotal?: number
}

export interface ImperativeHandle {
  clearFocus: () => void
}

export const EtcCell = forwardRef<ImperativeHandle, Props>(
  (
    {
      card,
      estimated,
      estimateMode,
      logged,
      rowIndex,
      showLoading,
      taskTotal = 0,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const [isFocused, setIsFocused] = useState(false)
    const divRef = useRef<HTMLDivElement>(null)
    const id = useId()
    const etcDisabledTooltipId = `etc-disabled-${id}`
    const totalTimeLoggedTooltipId = `total-time-logged-${id}`

    useOnClickOutside(divRef, () => setIsFocused(false))

    useImperativeHandle(
      ref,
      () => ({ clearFocus: () => setIsFocused(false) }),
      [setIsFocused],
    )

    const disabled = estimateMode === TaskEstimateMode.DISABLED

    const etc = useMemo(() => {
      if (estimated === undefined) return

      return Math.max(estimated - (logged ?? 0), 0)
    }, [estimated, logged])

    return (
      <>
        <GridCell
          className={twJoin('relative', !disabled && 'cursor-pointer')}
          colStart="totalByTask"
          editable={!disabled}
          isFocused={!disabled && isFocused}
          onClick={() => !disabled && setIsFocused(true)}
          rowIndex={rowIndex}
        >
          {disabled ? (
            showLoading ? (
              <div className="flex items-center grow justify-center">
                <Spinner />
              </div>
            ) : (
              <div className="flex flex-row px-2 items-center grow justify-between">
                <span
                  className="fa-layers fa-fw text-neutral-700"
                  data-tooltip-id={etcDisabledTooltipId}
                >
                  <FontAwesomeIcon icon={faCalculator} />
                  <FontAwesomeIcon
                    icon={faSlashForward}
                    size="lg"
                    transform="rotate-280"
                  />
                  <FontAwesomeIcon
                    icon={faSlashForward}
                    size="lg"
                    className="text-white"
                    transform="down-2 rotate-280"
                  />
                </span>
                <div data-tooltip-id={totalTimeLoggedTooltipId}>
                  <TaskTotal
                    total={taskTotal}
                    renderer={renderDaysHoursMinutes}
                  />
                </div>
                <Tooltip
                  id={etcDisabledTooltipId}
                  delayShow={500}
                  place="bottom"
                  positionStrategy="fixed"
                >
                  {t('components.timeEntryTable.etcDisabledForTask')}
                </Tooltip>
                <Tooltip
                  id={totalTimeLoggedTooltipId}
                  place="top"
                  delayShow={1000}
                >
                  {t('components.timeEntryTable.totalTimeLogged')}
                </Tooltip>
              </div>
            )
          ) : (
            <>
              <Bars
                className="px-2"
                disableEstimate={
                  estimateMode === TaskEstimateMode.BURN_DOWN_ONLY
                }
                etc={etc}
                logged={logged}
                showLoading={showLoading}
              />
              {isFocused && (
                <div
                  ref={divRef}
                  className="absolute top-[110%] right-0 z-10 bg-white"
                  onClick={(event) => event.stopPropagation()}
                >
                  {card({ clearFocus: () => setIsFocused(false) })}
                </div>
              )}
            </>
          )}
        </GridCell>
      </>
    )
  },
)

EtcCell.displayName = 'EtcCell'
