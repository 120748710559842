import { Input } from 'components/form/input'
import { Label } from 'components/form/label'
import { ModalForm } from 'components/notifications'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  defaultName?: string
  isSaving: boolean
  onCancel: () => void
  onSave: (name: string) => void
}

export const AdminManagedGroupNameForm: FC<Props> = ({
  defaultName = '',
  isSaving,
  onCancel,
  onSave,
}) => {
  const [value, setValue] = useState(defaultName)
  const { t } = useTranslation()

  const formFields = (
    <>
      <span className="text-xs text-neutral-600 font-normal mb-2">
        {t('features.adminManagedGroups.enterAName')}
      </span>
      <div>
        <Label htmlFor="admin-managed-group-name">
          {t('features.adminManagedGroups.groupName')}
        </Label>
        <Input
          autoFocus
          id="admin-managed-group-name"
          value={value}
          onChange={(event) => setValue(event.currentTarget.value)}
        />
      </div>
    </>
  )

  return (
    <ModalForm
      fields={formFields}
      onCancel={() => onCancel()}
      onSave={() => onSave(value)}
      isSaving={isSaving}
    />
  )
}
