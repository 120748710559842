import { FC } from 'react'
import { useApprovalEventLog } from '../hooks/useApprovalEventLog'
import { ApprovalHistory } from './approval-history'
import { Spinner } from 'components/loaders'

interface Props {
  approvalId?: number
}

export const ApprovalHistoryTab: FC<Props> = ({ approvalId }) => {
  const { data: events = [], isLoading } = useApprovalEventLog(approvalId)

  if (approvalId !== undefined && isLoading)
    return (
      <div className="mt-3 text-center">
        <Spinner />
      </div>
    )

  return <ApprovalHistory events={events} />
}
