import { WeekSelector } from 'components/navigation'
import { FC, ReactNode } from 'react'

interface Props {
  children?: ReactNode
  onWeekSelect: (startOfWeek: DateTime) => void
  selectedWeek: DateTime
}

export const WeekSelectorSubHeader: FC<Props> = ({
  children,
  onWeekSelect,
  selectedWeek,
}) => {
  return (
    <div className="z-20 flex flex-row items-center px-8 pt-6 pb-4">
      <WeekSelector
        onWeekSelect={onWeekSelect}
        selectedWeek={selectedWeek}
        variant="light"
      />
      <div className="flex flex-row items-center ml-auto">{children}</div>
    </div>
  )
}
