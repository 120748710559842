import { useApprovals } from '@hooks/useApprovals'
import { useDebouncedState } from '@hooks/useDebouncedState'
import { useSearchEmployees } from '@hooks/useEmployee'
import { useSet } from '@hooks/useSet'
import { useWeekDates } from '@hooks/useWeekDates'
import { coerceIntoArray } from '@utils/coerceIntoArray'
import {
  Button,
  FilterWorkersWithVisibleItems,
  FilterWorkerssWithVisibleItemsCounts,
  FilterWorkerWithVisibleItemsButtonGroup,
  StatusMiniSilos,
} from 'components/buttons'
import { WeekSelectorSubHeader } from 'components/page-header'
import { FC, useEffect, useMemo, useState, useCallback } from 'react'
import { toast } from '@lib/toasts'
import { useTranslation } from 'react-i18next'
import { Status } from 'types'
import { Header } from '../components/Header'
import { Details as SidebarDetails, Sidebar } from '../components/sidebar'
import { Table, useTableRef } from '../components/Table'
import { getApprovalStatus } from '../helpers/getApprovalStatus'
import { useBulkApproval, useBulkUnapproval } from '../hooks/useBulkApprovals'
import { useFiltering } from '../hooks/useFiltering'
import { WorkerApprovalsAndApprovalDomain } from '../types'
import { groupApprovalsByDomainAndWorker } from '../utils/groupApprovalsByDomainAndWorker'
import { useModal } from '@hooks/useModal'
import { isEmployee } from '@utils/workers'
import {
  AnomalousTimeCardButtons,
  Anomalies,
  AnomalyCounts,
} from 'components/buttons/AnomalousTimeCardButtons'
import { Divider } from 'components/filter-bar'

export const Approvals: FC = () => {
  const { t } = useTranslation()
  const modal = useModal()
  const {
    set: selectedApprovalIds,
    add: selectApproval,
    remove: deselectApproval,
    clear: deselectAllApprovals,
    toggle: toggleApproval,
  } = useSet<number>()
  const {
    start: selectedWeek,
    set: setSelectedWeek,
    range,
    end: endOfWeek,
  } = useWeekDates()
  const {
    set: hiddenStatuses,
    add: hideStatus,
    remove: showStatus,
    clear: showAllStatuses,
  } = useSet<Status>()
  const [
    filterEmployeesWithVisibleApprovals,
    setFilterEmployeesWithVisibleApprovals,
  ] = useState<FilterWorkersWithVisibleItems>('all')
  const [employeeSidebarOpened, setEmployeeSidebarOpened] = useState(false)
  const [sidebarDetails, setSidebarDetails] = useState<SidebarDetails>()
  const {
    set: hiddenDays,
    add: hideDays,
    remove: showDays,
    clear: showAllDays,
  } = useSet<number>()
  const {
    state: employeeNameFilter,
    debounced: debouncedEmployeeNameFilter,
    setState: setEmployeeNameFilter,
  } = useDebouncedState<string>('')
  const {
    state: filteredDepartmentIds,
    debounced: debouncedDepartmentIds,
    setState: setFilteredDepartmentIds,
  } = useDebouncedState<Set<string>>(new Set())
  const {
    state: filteredApprovalDomainIds,
    debounced: debouncedApprovalDomainIds,
    setState: setFilteredApprovalDomainIds,
  } = useDebouncedState<Set<string>>(new Set())
  const {
    state: filteredFacilityIds,
    debounced: debouncedFilteredFacilityIds,
    setState: setFilteredFacilityIds,
  } = useDebouncedState<Set<number>>(new Set())
  const [filteredAnomalyTypes, setFilteredAnomalyTypes] = useState<
    Set<Anomalies>
  >(new Set())

  const tableRef = useTableRef()

  const employeesQuery = useSearchEmployees(selectedWeek, endOfWeek)
  const approvalsQuery = useApprovals(selectedWeek, endOfWeek)
  const approveApprovals = useBulkApproval()
  const unapproveApprovals = useBulkUnapproval()

  const isLoading = useMemo(
    () =>
      employeesQuery.data === undefined || approvalsQuery.data === undefined,
    [employeesQuery.data, approvalsQuery.data],
  )

  const workersWithApprovalsAndApprovalDomain: WorkerApprovalsAndApprovalDomain[] =
    useMemo(() => {
      if (
        employeesQuery.data === undefined ||
        approvalsQuery.data === undefined
      )
        return []

      const approvals = approvalsQuery.data

      const approvalsByDomainAndWorker =
        groupApprovalsByDomainAndWorker(approvals)

      // Build array of WorkerApprovalsAndApprovalDomain objects
      // for each worker returned by employee search,
      // with either an undefined approvalDomain with empty approvals array
      // if the worker does not have any approvals,
      // or a WorkerApprovalsAndApprovalDomain (approvalDomain + approvals obj)
      // for each unique worker + approval domain combo
      // This ultimately ensures we display a row for every worker
      // the approver can see, even if the worker does not have any
      // approvals during the selected week, and also ensures
      // we display a row for each unique worker+domain combo.
      return employeesQuery.data.flatMap<WorkerApprovalsAndApprovalDomain>(
        (employee) => {
          const workerApprovalsAndDomains = approvalsByDomainAndWorker[
            employee.workdayWorkerId
          ]
            ? Object.values(
                approvalsByDomainAndWorker[employee.workdayWorkerId],
              )
            : [
                // When a worker appears without any approvals,
                // we consider that worker as being part of their
                // AG domain with no approvals.
                // We ultimately want to render a row for the worker in their AG
                // domain without any approvals/time cards.
                // This is useful when a worker is on leave or has no time cards
                // for some other reason — they'll still be included in queries
                // for AG approvers and TLAs, and it makes more sense from a
                // UX perspective to attribute their row to their AG vs. leaving
                // the domain blank.
                {
                  approvalDomain: {
                    id: employee.approvalGroup.approvalDomainId,
                    type: 'approval_group',
                    name: employee.approvalGroup.name,
                  } as ApprovalDomain,
                  approvals: [],
                },
              ]

          return workerApprovalsAndDomains.map((approvalsAndDomain) => ({
            worker: employee,
            ...approvalsAndDomain,
          }))
        },
      )
    }, [employeesQuery.data, approvalsQuery.data])

  const selectedApprovals = useMemo(() => {
    if (approvalsQuery.data === undefined) return []

    return approvalsQuery.data.filter((approval) =>
      selectedApprovalIds.has(approval.id),
    )
  }, [approvalsQuery.data, selectedApprovalIds])

  const { filters, applyFilters, filtersWithout } = useFiltering({
    hiddenDays,
    filteredAnomalyTypes,
    hiddenStatuses,
    filterEmployeesWithVisibleApprovals,
    employeeNameFilter: debouncedEmployeeNameFilter,
    filteredFacilityIds: debouncedFilteredFacilityIds,
    filteredApprovalDomainIds: debouncedApprovalDomainIds,
    filteredDepartmentIds: debouncedDepartmentIds,
  })

  // This is used as a convenient way to:
  // 1. Identify when *any* filter has changed
  // 2. Identify if *any* filters are enabled (or even how many)
  const enabledFilters = useMemo(
    () => ({
      hiddenDays: hiddenDays.size > 0 ? hiddenDays : false,
      hiddenStatuses: hiddenStatuses.size > 0 ? hiddenStatuses : false,
      employeeNameFilter:
        employeeNameFilter !== '' ? employeeNameFilter : false,
      filteredFacilityIds:
        filteredFacilityIds.size > 0 ? filteredFacilityIds : false,
      filteredApprovalDomainIds:
        filteredApprovalDomainIds.size > 0 ? filteredApprovalDomainIds : false,
      filteredDepartmentIds:
        filteredDepartmentIds.size > 0 ? filteredDepartmentIds : false,
      filterEmployeesWithVisibleApprovals:
        filterEmployeesWithVisibleApprovals === 'all'
          ? false
          : filterEmployeesWithVisibleApprovals,
      filterAnomalyTypes:
        filteredAnomalyTypes.size > 0 ? filteredAnomalyTypes : false,
    }),
    [
      hiddenDays,
      hiddenStatuses,
      employeeNameFilter,
      filteredFacilityIds,
      filteredApprovalDomainIds,
      filteredDepartmentIds,
      filterEmployeesWithVisibleApprovals,
      filteredAnomalyTypes,
    ],
  )

  const filteredWorkersWithApprovalsAndApprovalDomain = useMemo(
    () => applyFilters(workersWithApprovalsAndApprovalDomain, filters),
    [workersWithApprovalsAndApprovalDomain, filters, applyFilters],
  )

  const employeesWithVisibleTimeCardsFilterCounts =
    useMemo<FilterWorkerssWithVisibleItemsCounts>(() => {
      const filteredWithoutVisibleTimeCardFilter = applyFilters(
        workersWithApprovalsAndApprovalDomain,
        filtersWithout('visibleApprovalFilter'),
      )

      const all = filteredWithoutVisibleTimeCardFilter.length
      const none = filteredWithoutVisibleTimeCardFilter.filter(
        ({ approvals }) => approvals.length === 0,
      ).length
      const atLeastOne = all - none

      return { all, atLeastOne, none }
    }, [workersWithApprovalsAndApprovalDomain, applyFilters, filtersWithout])

  const { statusCounts, selectedStatusCounts } = useMemo(() => {
    const filteredWithoutStatusFilter = applyFilters(
      workersWithApprovalsAndApprovalDomain,
      filtersWithout('statusFilter'),
    )

    const statusCounts = {
      [Status.Open]: 0,
      [Status.Approved]: 0,
      [Status.Sent]: 0,
      [Status.Submitted]: 0,
    }

    const selectedStatusCounts = { ...statusCounts }

    filteredWithoutStatusFilter.forEach((row) => {
      row.approvals.forEach((approval) => {
        const status = getApprovalStatus(approval)
        statusCounts[status]++

        if (!selectedApprovalIds.has(approval.id)) return

        selectedStatusCounts[status]++
      })
    })

    return { statusCounts, selectedStatusCounts }
  }, [
    applyFilters,
    workersWithApprovalsAndApprovalDomain,
    filtersWithout,
    selectedApprovalIds,
  ])

  const filterAnomalyTypeCounts = useMemo<AnomalyCounts>(() => {
    const counts = {
      NoBreak: 0,
      HasTaggedTime: 0,
    }

    const filteredWithoutAnomalyFilter = applyFilters(
      workersWithApprovalsAndApprovalDomain,
      filtersWithout('anomalyFilter'),
    )

    // We use filteredWithoutAnomalyFilter here because
    // we want the totals for each alert after applying
    // all other filters but without applying the anomaly filter itself
    // (so the anomaly totals remain visible even when filtered out by another anomaly filter)
    filteredWithoutAnomalyFilter.forEach(({ approvals }) => {
      approvals.forEach((approval) => {
        if (approval.totalDomainSecondsTagged > 0) counts.HasTaggedTime++
        if (approval.timeCard.totalBreaks === 0) counts.NoBreak++
      })
    })

    return counts
  }, [workersWithApprovalsAndApprovalDomain, filtersWithout, applyFilters])

  useEffect(
    () => deselectAllApprovals(),
    [enabledFilters, deselectAllApprovals],
  )

  const handleStatusSelect =
    (operation: 'select' | 'deselect') => (status: Status | Status[]) => {
      const statusesToSelect = coerceIntoArray(status)

      const approvalIds = filteredWorkersWithApprovalsAndApprovalDomain.flatMap(
        (row) => {
          return row.approvals
            .filter((approval) => {
              const approvalStatus = getApprovalStatus(approval)
              return statusesToSelect.includes(approvalStatus)
            })
            .map((approval) => approval.id)
        },
        [],
      )

      const operationFn =
        operation === 'select' ? selectApproval : deselectApproval
      operationFn(approvalIds)
    }

  const approvableSelectedApprovals = useMemo(
    () =>
      selectedApprovals.filter(
        (approval) =>
          !approval.approved &&
          approval.timeCard.submitted &&
          !approval.timeCard.sent_to_payroll,
      ),
    [selectedApprovals],
  )

  const unapprovableSelectedApprovals = useMemo(
    () =>
      selectedApprovals.filter(
        (approval) => approval.approved && !approval.timeCard.sent_to_payroll,
      ),
    [selectedApprovals],
  )

  const approveSelectedTimeCards = async () => {
    try {
      await approveApprovals.mutateAsync(
        approvableSelectedApprovals.map((approval) => approval.id),
      )

      toast({
        title: t('features.approvals.approvalsSubmitted'),
        variant: 'success',
        content: t(
          'features.approvals.numberOfApprovalsSuccessfullySubmitted',
          { count: approvableSelectedApprovals.length },
        ),
      })
    } catch {
      modal.alert({
        content: t('features.approvals.unableToApproveSelection'),
      })
    }
  }

  const unapproveSelectedTimeCards = async () => {
    try {
      await unapproveApprovals.mutateAsync(
        unapprovableSelectedApprovals.map((approval) => approval.id),
      )

      toast({
        title: t('features.approvals.unapprovalSuccessful'),
        variant: 'default',
        content: t(
          'features.approvals.numberOfApprovalsSuccessfullyUnapproved',
          { count: unapprovableSelectedApprovals.length },
        ),
      })
    } catch {
      modal.alert({
        content: t('features.approvals.unableToUnapproveSelection'),
      })
    }
  }

  const closeSidebar = useCallback(() => {
    setEmployeeSidebarOpened(false)
    setSidebarDetails(undefined)
    tableRef.current?.clearFocusedCell()
  }, [tableRef])

  const onCellFocusChange = useCallback(
    (
      selected:
        | { worker?: TWorker; approval?: Approval; date: DateTime }
        | 'none' = 'none',
    ) => {
      if (selected === 'none') {
        setEmployeeSidebarOpened(false)
        setSidebarDetails(undefined)
      } else {
        const { approval, worker, date } = selected
        setEmployeeSidebarOpened(true)
        setSidebarDetails({
          approval,
          date: approval?.timeCard.date ?? date,
          employeeDetails: worker && {
            workdayWorkerId: worker.workdayWorkerId,
            department: worker.department.name,
            fullName: worker.fullName,
            type: isEmployee(worker)
              ? t(`payTypes.${worker.payType}`)
              : t(`contingentWorkerTypes.${worker.contingentWorkerType}`),
            title: worker.jobTitle ?? undefined,
            profileImageUrl: worker.profilePictureUrl ?? undefined,
          },
          timeCardId: approval?.timeCard.id,
          userId: worker?.user.id,
        })
      }
    },
    [t],
  )

  const reset = () => {
    showAllDays()
    showAllStatuses()
    deselectAllApprovals()
    setFilteredAnomalyTypes(new Set())
    setFilterEmployeesWithVisibleApprovals('all')
    setEmployeeNameFilter('')
    setFilteredDepartmentIds(new Set())
    setFilteredApprovalDomainIds(new Set())
    setFilteredFacilityIds(new Set())
  }

  const resetDisabled =
    hiddenDays.size === 0 &&
    hiddenStatuses.size === 0 &&
    selectedApprovalIds.size === 0 &&
    filterEmployeesWithVisibleApprovals === 'all' &&
    employeeNameFilter === '' &&
    filteredDepartmentIds.size === 0 &&
    filteredApprovalDomainIds.size === 0 &&
    filteredFacilityIds.size === 0 &&
    filteredAnomalyTypes.size === 0

  return (
    <div className="flex flex-grow">
      <div className="flex flex-col flex-grow">
        <Header
          disableApproveSelected={approvableSelectedApprovals.length === 0}
          disableUnapproveSelected={unapprovableSelectedApprovals.length === 0}
          onApproveSelected={() => {
            modal.confirm({
              title: t('features.approvals.confirmApproval'),
              content: t('features.approvals.approveSelectedTimeCards'),
              onConfirmAsync: approveSelectedTimeCards,
              primaryButtonPendingText: t('features.approvals.approving'),
            })
          }}
          onUnapproveSelected={() => {
            modal.confirm({
              title: t('features.approvals.confirmUnapproval'),
              content: t('features.approvals.unapproveSelectedTimeCards'),
              onConfirmAsync: unapproveSelectedTimeCards,
              primaryButtonPendingText: t('features.approvals.unapproving'),
            })
          }}
          selectedApprovals={selectedApprovals}
          title={t('common.approvals')}
        />
        <WeekSelectorSubHeader
          onWeekSelect={(startOfWeek) => {
            setSelectedWeek(startOfWeek)
            closeSidebar() // Close sidebar when changing weeks
          }}
          selectedWeek={selectedWeek}
        >
          <FilterWorkerWithVisibleItemsButtonGroup
            counts={employeesWithVisibleTimeCardsFilterCounts}
            onChange={setFilterEmployeesWithVisibleApprovals}
            itemType="approval"
            value={filterEmployeesWithVisibleApprovals}
            loading={isLoading}
          />
          <Divider />
          <AnomalousTimeCardButtons
            counts={filterAnomalyTypeCounts}
            onChange={setFilteredAnomalyTypes}
            selected={filteredAnomalyTypes}
          />
          <Divider />
          <StatusMiniSilos
            counts={statusCounts}
            hiddenStatuses={hiddenStatuses}
            onHide={hideStatus}
            onSelect={handleStatusSelect('select')}
            onShow={showStatus}
            onUnselect={handleStatusSelect('deselect')}
            selectedCounts={selectedStatusCounts}
            loading={isLoading}
          />
          <Divider />
          <Button
            className="px-4 text-sm font-medium text-neutral-900"
            onClick={reset}
            disabled={resetDisabled}
            variant="outlined"
          >
            {t('common.reset')}
          </Button>
        </WeekSelectorSubHeader>
        <Table
          ref={tableRef}
          clearSelections={deselectAllApprovals}
          deselectApproval={deselectApproval}
          filteredApprovalDomainIds={filteredApprovalDomainIds}
          filteredDepartmentIds={filteredDepartmentIds}
          filteredEmployeeName={employeeNameFilter}
          filteredFacilityIds={filteredFacilityIds}
          hiddenDays={hiddenDays}
          hiddenStatuses={hiddenStatuses}
          onApprovalDomainFilterChange={setFilteredApprovalDomainIds}
          onDepartmentFilterChange={setFilteredDepartmentIds}
          onEmployeeNameFilterChange={setEmployeeNameFilter}
          onFacilityFilterChange={setFilteredFacilityIds}
          onHideDays={hideDays}
          onShowDays={showDays}
          selectApproval={selectApproval}
          selectedApprovalIds={selectedApprovalIds}
          selectedApprovals={selectedApprovals}
          toggleApproval={toggleApproval}
          weekRange={range}
          workersApprovalsAndApprovalDomainsAfterFiltering={
            filteredWorkersWithApprovalsAndApprovalDomain
          }
          workersApprovalsAndApprovalDomainsBeforeFiltering={
            workersWithApprovalsAndApprovalDomain
          }
          loading={isLoading}
          sidebarOpened={employeeSidebarOpened}
          onCellFocusChange={onCellFocusChange}
        />
      </div>
      {employeeSidebarOpened && sidebarDetails && (
        <Sidebar {...sidebarDetails} onClose={closeSidebar} />
      )}
    </div>
  )
}
