import { FilterWorkersWithVisibleItems } from 'components/buttons'
import { useCallback, useMemo } from 'react'
import { Status } from 'types'
import { getApprovalStatus } from '../helpers/getApprovalStatus'
import { WorkerApprovalsAndApprovalDomain } from '../types'
import { Anomalies } from 'components/buttons/AnomalousTimeCardButtons'

// Returns true if the provided Approval
// matches any of the provided filtered anomalies,
// false otherwise.
const approvalMatchesAnyAnomalyType = (
  approval: Omit<Approval, 'approvalDomain'>,
  types: Set<Anomalies>,
) => {
  if (
    types.has(Anomalies.HasTaggedTime) &&
    approval.totalDomainSecondsTagged > 0
  )
    return true

  if (types.has(Anomalies.NoBreak) && approval.timeCard.totalBreaks === 0)
    return true

  return false
}

// Returns true if the provided worker should be included
type WorkerFilterFn = (worker: TWorker) => boolean

// Returns true if the provided approvalDomain should be included
type ApprovalDomainFilterFn = (approvalDomain?: ApprovalDomain) => boolean

// Returns true if the provided time card should be included
type ApprovalFilterFn = (approval: Omit<Approval, 'approvalDomain'>) => boolean

// Returns true if the provided worker and time cards should be included
// If false is returned, the entire worker is excluded
type WorkerAndApprovalsFilterFn = (
  worker: TWorker,
  approvals: Omit<Approval, 'approvalDomain'>[],
) => boolean

interface Options {
  hiddenDays: Set<number>
  filteredAnomalyTypes: Set<Anomalies>
  hiddenStatuses: Set<Status>
  employeeNameFilter: string
  filteredFacilityIds: Set<number>
  filteredApprovalDomainIds: Set<string>
  filteredDepartmentIds: Set<string>
  filterEmployeesWithVisibleApprovals: FilterWorkersWithVisibleItems
}

interface FilterMap {
  // Filters that just care about the worker
  worker: {
    [key: string]: WorkerFilterFn
  }

  // Filters that just care about the approval domain
  approvalDomain: {
    [key: string]: ApprovalDomainFilterFn
  }

  // Filters that just care about the approval
  approval: {
    [key: string]: ApprovalFilterFn
  }

  // Filters that care about the combined worker and approval collection
  workerAndApprovals: {
    [key: string]: WorkerAndApprovalsFilterFn
  }
}

export const useFiltering = ({
  hiddenDays,
  hiddenStatuses,
  filteredAnomalyTypes,
  employeeNameFilter,
  filteredFacilityIds,
  filteredApprovalDomainIds,
  filteredDepartmentIds,
  filterEmployeesWithVisibleApprovals,
}: Options) => {
  const dayFilter = useCallback<ApprovalFilterFn>(
    (approval) => {
      if (hiddenDays.size === 0) return true

      return !hiddenDays.has(approval.timeCard.date.weekday)
    },
    [hiddenDays],
  )

  const anomalyFilter = useCallback<ApprovalFilterFn>(
    (approval) => {
      if (filteredAnomalyTypes.size === 0) return true

      return approvalMatchesAnyAnomalyType(approval, filteredAnomalyTypes)
    },
    [filteredAnomalyTypes],
  )

  const statusFilter = useCallback<ApprovalFilterFn>(
    (approval) => {
      if (hiddenStatuses.size === 0) return true

      const status = getApprovalStatus(approval)
      return !hiddenStatuses.has(status)
    },
    [hiddenStatuses],
  )

  const approvalDomainFilter = useCallback<ApprovalDomainFilterFn>(
    (approvalDomain) => {
      if (filteredApprovalDomainIds.size === 0) return true
      if (approvalDomain === undefined) return false

      return filteredApprovalDomainIds.has(approvalDomain.id)
    },
    [filteredApprovalDomainIds],
  )

  const visibleApprovalFilter = useCallback<WorkerAndApprovalsFilterFn>(
    (_worker, approvals) => {
      return {
        all: true,
        atLeastOne: approvals.length > 0,
        none: approvals.length === 0,
      }[filterEmployeesWithVisibleApprovals]
    },
    [filterEmployeesWithVisibleApprovals],
  )

  const workerAttributeFilter = useCallback<WorkerFilterFn>(
    (worker) => {
      const normalizedSearch = employeeNameFilter.trim().toLowerCase()

      const notFilteringNames = normalizedSearch === ''
      const notFilteringFacilities = filteredFacilityIds.size === 0
      const notFilteringApprovalGroups = filteredApprovalDomainIds.size === 0
      const notFilteringDepartments = filteredDepartmentIds.size === 0

      // Optimization for when no filters are applied
      if (
        notFilteringNames &&
        notFilteringFacilities &&
        notFilteringApprovalGroups &&
        notFilteringDepartments
      )
        return true

      return (
        (notFilteringNames ||
          worker.fullName.toLowerCase().includes(normalizedSearch)) &&
        (notFilteringFacilities ||
          filteredFacilityIds.has(worker.facility.id)) &&
        (notFilteringDepartments ||
          (worker.department.id !== null &&
            filteredDepartmentIds.has(worker.department.id)))
      )
    },
    [
      employeeNameFilter,
      filteredFacilityIds,
      filteredApprovalDomainIds,
      filteredDepartmentIds,
    ],
  )

  const filters = useMemo<FilterMap>(
    () => ({
      worker: { workerAttributeFilter },
      approval: { dayFilter, statusFilter, anomalyFilter },
      approvalDomain: { approvalDomainFilter },
      workerAndApprovals: { visibleApprovalFilter },
    }),
    [
      dayFilter,
      statusFilter,
      anomalyFilter,
      approvalDomainFilter,
      visibleApprovalFilter,
      workerAttributeFilter,
    ],
  )

  const applyFilters = useCallback(
    (
      workerApprovalsAndApprovalDomain: WorkerApprovalsAndApprovalDomain[],
      filters: FilterMap,
    ) =>
      workerApprovalsAndApprovalDomain.reduce<
        WorkerApprovalsAndApprovalDomain[]
      >((acc, { worker, approvals, approvalDomain }) => {
        // Filter workers first, no need to proceed if the worker is excluded
        const includeWorker = Object.values(filters.worker).every((filter) =>
          filter(worker),
        )

        const includeApprovalDomain = Object.values(
          filters.approvalDomain,
        ).every((filter) => filter(approvalDomain))

        if (includeWorker && includeApprovalDomain) {
          // Filter approvals next
          const filteredApprovals = approvals.filter((approval) => {
            return Object.values(filters.approval).every((filter) =>
              filter(approval),
            )
          })

          // Determine if the worker should be included based on the new filtered Time Cards
          const includeWorkerAndApprovals = Object.values(
            filters.workerAndApprovals,
          ).every((filter) => filter(worker, filteredApprovals))

          if (includeWorkerAndApprovals) {
            acc.push({
              worker,
              approvals: filteredApprovals,
              approvalDomain,
            })
          }
        }

        return acc
      }, []),
    [],
  )

  // Given a filter key, return a FilterMap without that filter
  // We assume here that filter keys never clash, even across types
  // (i.e. a worker/approval/workerAndApprovals filters never share a key)
  const filtersWithout = useCallback(
    (filterKey: string) => {
      const {
        worker: { [filterKey]: _excludedWorkersFilter, ...worker },
        approval: { [filterKey]: _excludedApprovalFilter, ...approval },
        approvalDomain: {
          [filterKey]: _excludedApprovalDomainsFilter,
          ...approvalDomain
        },
        workerAndApprovals: {
          [filterKey]: _excludedWorkerAndApprovalsFilter,
          ...workerAndApprovals
        },
      } = filters

      return { worker, approval, approvalDomain, workerAndApprovals }
    },
    [filters],
  )

  return { filters, applyFilters, filtersWithout }
}
