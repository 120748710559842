import { FC, useState } from 'react'
import { Label } from 'components/form/label'
import { Input } from 'components/form/input'
import { UserSelection } from '@features/user-selection'
import { useSet } from '@hooks/useSet'
import { ModalForm } from 'components/notifications'
import { useTranslation } from 'react-i18next'

interface Props {
  approvalGroup?: AdminApprovalGroup
  cancel: () => void
  save: (groupName: string, approverUserIds: number[]) => unknown
  isSaving: boolean
}

export const ApprovalGroupForm: FC<Props> = ({
  approvalGroup,
  cancel,
  save,
  isSaving,
}) => {
  const [groupName, setGroupName] = useState<string>(approvalGroup?.name ?? '')
  const {
    set: approverUserIds,
    add: addApproverUserId,
    remove: removeApproverUserId,
  } = useSet<number>(new Set(approvalGroup?.approverUsers.map((u) => u.id)))
  const { t } = useTranslation()

  const formFields = (
    <>
      <div>
        <Label>{t('features.approvalGroupsAdmin.groupName')}</Label>
        <Input
          autoFocus={true}
          value={groupName}
          onChange={(event) => setGroupName(event.target.value)}
          required
        />
      </div>
      <UserSelection
        label={t('common.approvers')}
        placeholder={t('common.searchByName')}
        selectedUserIds={approverUserIds}
        onSelect={(user) => addApproverUserId(user.id)}
        onRemove={(user) => removeApproverUserId(user.id)}
      />
    </>
  )

  return (
    <ModalForm
      fields={formFields}
      onCancel={cancel}
      onSave={() => save(groupName, Array.from(approverUserIds))}
      isSaving={isSaving}
    />
  )
}
