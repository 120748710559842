import { useWeekDates } from './useWeekDates'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { notesKeys } from '@lib/keys'
import { deleteNote, getNotes, putNote } from '@lib/api'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useEmployee } from './useEmployee'

interface UserIdOptions {
  userId: number
  workdayWorkerId?: undefined
}

interface WorkdayWorkerIdOptions {
  userId?: undefined
  workdayWorkerId: number
}

type Options = (UserIdOptions | WorkdayWorkerIdOptions) & { enabled?: boolean }

export const useNotes = ({ userId, workdayWorkerId, enabled }: Options) => {
  const employeeQuery = useEmployee({
    userId: userId ?? 0,
    enabled: workdayWorkerId === undefined && enabled,
  })

  const employeeId =
    workdayWorkerId === undefined
      ? employeeQuery.data?.workdayWorkerId ?? 0
      : workdayWorkerId

  const { start, end } = useWeekDates()

  return useQuery(
    notesKeys.search(employeeId, start, end),
    () => getNotes(employeeId, start, end),
    { enabled: employeeId !== 0 && enabled },
  )
}

export const useNoteForDate = (date: DateTime, options: Options) => {
  const notesQuery = useNotes(options)

  return useMemo(() => {
    return {
      isLoading: notesQuery.isLoading,
      note: notesQuery.data?.find((note) => note.date.hasSame(date, 'day')),
    }
  }, [date, notesQuery.data, notesQuery.isLoading])
}

export const usePostNote = ({ userId }: UserIdOptions) => {
  const queryClient = useQueryClient()
  const employeeQuery = useEmployee({ userId })
  const employeeId = employeeQuery.data?.workdayWorkerId ?? 0
  const { start, end } = useWeekDates()

  return useMutation({
    mutationKey: notesKeys.search(employeeId, start, end),
    mutationFn: (options: { date: DateTime; note: string }) => {
      if (employeeId === 0)
        return Promise.reject('Unable to post note.  Invalid employee id of 0')

      return putNote(employeeId, options.date, options.note)
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(
        notesKeys.search(employeeId, start, end),
      )
    },
  })
}

export const useDeleteNote = ({ userId }: UserIdOptions) => {
  const queryClient = useQueryClient()
  const employeeQuery = useEmployee({ userId })
  const employeeId = employeeQuery.data?.workdayWorkerId ?? 0
  const { start, end } = useWeekDates()

  return useMutation({
    mutationKey: notesKeys.search(employeeId, start, end),
    mutationFn: (options: { date: DateTime; note: string }) => {
      if (employeeId === 0)
        return Promise.reject(
          'Unable to delete note.  Invalid employee id of 0',
        )

      return deleteNote(employeeId, options.date)
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(
        notesKeys.search(employeeId, start, end),
      )
    },
  })
}
