import { UseQueryResult } from '@tanstack/react-query'
import { SearchSelection } from 'components/form/search-selection'
import { ReactNode, useState as reactUseState } from 'react'
import { twMerge } from '@lib/tailwind-merge'
import { noop } from '@utils/noop'

type Props<T, R extends string | number> = {
  className?: string
  filter: (entity: T, search: string) => boolean
  label?: string
  onEnter?: (entity: T) => void
  placeholder: string
  isSelected: (entity: T, selectedIds: Set<R>) => boolean
  renderNoItemSelected?: () => ReactNode
  renderSearchItem: (entity: T, clickHandler: () => void) => ReactNode
  renderSelectedItem: (entity: T) => ReactNode
  selectedIds: Set<R>
  useState?: (initialValue?: string) => [string, (state: string) => void]
} & (
  | {
      useQuery: () => UseQueryResult<T[], unknown>
    }
  | {
      accessor: (v: T) => R
      values: T[]
    }
)

export const EntityPicker = <T, R extends string | number>({
  className,
  filter,
  label,
  onEnter = noop,
  placeholder,
  isSelected,
  renderNoItemSelected,
  renderSearchItem,
  renderSelectedItem,
  selectedIds,
  useState = reactUseState,
  ...props
}: Props<T, R>) => {
  const [search, setSearch] = useState('')

  const { data, isFetching, isLoading } =
    'useQuery' in props
      ? props.useQuery()
      : { data: props.values, isFetching: false, isLoading: false }

  const filteredData = data?.filter((d) => filter(d, search)) ?? []
  const selectedData =
    data?.filter((user) => isSelected(user, selectedIds)) ?? []

  return (
    <div className={twMerge('flex flex-col', className)}>
      <SearchSelection
        expectedItemHeight={40}
        items={filteredData}
        isFetching={isFetching}
        isInitiallyLoading={isLoading}
        label={label}
        onEnter={onEnter}
        onSearch={setSearch}
        placeholder={placeholder}
        renderItem={renderSearchItem}
        totalItems={filteredData.length}
        value={search}
      />
      <div className="flex flex-col mt-4">
        {selectedData.length === 0
          ? renderNoItemSelected && renderNoItemSelected()
          : selectedData.map(renderSelectedItem)}
      </div>
    </div>
  )
}
