import { FC, useCallback, useState } from 'react'
import { useUpdateProject } from '../hooks/useUpdateProject'
import { useTranslation } from 'react-i18next'
import { toast } from '@lib/toasts'
import { ModalForm } from 'components/notifications'
import { Label } from 'components/form/label'
import { Input } from 'components/form/input'

interface Props {
  onCancel: () => void
  onSave: () => void
  project: AdminProject
}

export const RenameProjectForm: FC<Props> = ({ onCancel, onSave, project }) => {
  const { t } = useTranslation()
  const updateProject = useUpdateProject()

  const [name, setName] = useState(project.name)

  const save = useCallback(async () => {
    try {
      await updateProject.mutateAsync({
        id: project.id,
        name,
      })

      onSave()

      toast({
        title: t('features.projectsAdmin.projectSaved'),
        variant: 'success',
        content: t('features.projectsAdmin.successfullySavedProject', {
          name: name,
        }),
      })
    } catch (error) {
      toast({
        title: t('features.projectsAdmin.unableToSaveProject'),
        variant: 'error',
        content: t('features.projectsAdmin.failedToSaveProject', {
          name: name,
        }),
      })
    }
  }, [onSave, updateProject, project, t, name])

  const formFields = (
    <>
      <div>
        <Label>{t('features.projectsAdmin.projectName')}</Label>
        <Input
          autoFocus
          value={name}
          onChange={(event) => setName(event.target.value)}
          required
        />
      </div>
    </>
  )

  return (
    <ModalForm
      fields={formFields}
      onCancel={() => onCancel()}
      onSave={() => void save()}
      isSaving={updateProject.isLoading}
    />
  )
}
