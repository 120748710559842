import {
  ApprovalGroupsAdmin,
  ApprovalsAdmin,
  EmployeesAdmin,
  ProjectsAdmin,
  SubmissionsAdmin,
  FacilitiesAdmin,
  managedGroupsRoutes,
} from '@features/admin'
import { NavigationSidebar } from '@features/navigation-sidebar'
import { settingsRoutes } from '@features/settings'
import { TimeLogging, TimeLoggingRouteContext } from '@features/time-logging'
import { Approvals } from '@features/approvals'
import { useAuth } from '@hooks/useAuth'
import { useInhibitKeyPressOverride } from '@hooks/useInhibitKeyPressOverride'
import { noop } from '@utils/noop'
import { MainLayout } from 'components/layout'
import { Spinner } from 'components/loaders'
import { AccountInfo } from 'components/navigation'
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { ModalProvider } from 'providers/ModalProvider'
import { useLanguage } from '@hooks/useLanguage'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import { reportingRoutes } from '@features/reporting'

const LanguageChanger: FC<{ children: ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation()
  const language = useLanguage()

  useEffect(() => {
    const changeLanguage = async () => {
      try {
        await i18n.changeLanguage(language)
      } catch (e) {
        Sentry.captureException(e)
      }
    }

    void changeLanguage()
  }, [language, i18n])

  return <>{children}</>
}

const AuthenticatedWrapper = () => {
  const { user, signOut, initialized } = useAuth()
  const [menuHidden, setMenuHidden] = useState(true)
  const navigate = useNavigate()
  const { registerMenuOpened, registerMenuClosed } =
    useInhibitKeyPressOverride()

  const setMenuState = useCallback(
    (state: 'open' | 'close') => {
      setMenuHidden(state === 'close')
      const register =
        state === 'open' ? registerMenuOpened : registerMenuClosed
      register('main-sidebar')
    },
    [registerMenuOpened, registerMenuClosed, setMenuHidden],
  )

  const closeMenu = useCallback(() => setMenuState('close'), [setMenuState])
  const openMenu = useCallback(() => setMenuState('open'), [setMenuState])

  const logout = async () => {
    await signOut()
    navigate('/logout')
  }

  if (!initialized)
    return (
      <MainLayout accountInfo={<AccountInfo />} onMenuOpen={noop}>
        <div className="flex-col flex-grow p-4 text-center">
          <h1 className="font-bold text-neutral-700">Signing In</h1>
          <h2 className="text-sm font-semibold text-neutral-500">
            Please wait
          </h2>
          <Spinner className="mt-4 text-3xl" />
        </div>
      </MainLayout>
    )

  if (!user) return <Navigate to="/login" replace={true} />

  // Hoping to debug: https://storiedsoftware.sentry.io/issues/6092129274/events/latest/?project=4507948472467456&statsPeriod=7d
  if (user.firstName === undefined || user.firstName === null) {
    Sentry.captureMessage(
      `User firstname is not defined within authenticated.tsx: ${JSON.stringify(
        user,
      )}`,
    )
  }

  /**
   * The ModalProvider within this file is inappropriate and smelly.  Since it's
   * responsible for rendering modals that may require authenticated (route)
   * information, it needs to be here. That's the smelly bit.  It likely
   * shouldn't be responsible for rendering authenticated information
   *
   * TODO - think of a better way of rendering modals.  We likely don't need a
   * provider.  We can make the caller responsible for rendering everything
   * it needs.  A custom hook will likely do nicely.
   *
   * - AF 6/27/23
   */
  return (
    <LanguageChanger>
      <ModalProvider>
        <MainLayout
          accountInfo={
            <AccountInfo
              imageUrl={user.profilePictureUrl}
              initial={user.firstName.charAt(0)}
              onLogout={() => void logout()}
            />
          }
          onMenuOpen={openMenu}
          sidebar={
            <NavigationSidebar hidden={menuHidden} onClose={closeMenu} />
          }
        >
          <Outlet />
        </MainLayout>
      </ModalProvider>
    </LanguageChanger>
  )
}

export const authenticatedRoutes = [
  {
    path: '/',
    element: <AuthenticatedWrapper />,
    children: [
      {
        path: '/time-logger',
        element: <TimeLoggingRouteContext />,
        children: [
          { path: 'user' }, // instructs TimeLoggingRouteContext to service this
          {
            path: 'user/:userId',
            element: <TimeLogging />,
          },
        ],
      },
      {
        path: '/admin/facilities',
        element: <FacilitiesAdmin />,
      },
      {
        path: '/admin/time-card-admin',
        element: <SubmissionsAdmin />,
      },
      ...managedGroupsRoutes,
      {
        path: '/admin/*',
        element: <Navigate to="/admin/time-card-admin" replace={true} />,
      },
      {
        path: '/projects',
        element: <ProjectsAdmin />,
      },
      {
        path: '/approvals-admin',
        element: <ApprovalsAdmin />,
      },
      {
        path: '/approval-groups',
        element: <ApprovalGroupsAdmin />,
      },
      {
        path: '/employees',
        element: <EmployeesAdmin />,
      },
      {
        path: '/approvals',
        element: <Approvals />,
      },

      // Redirect old-style domain-specific approval URLs to new combined approvals page
      // Can be removed eventually
      {
        path: '/approvals/*',
        element: <Navigate to="/approvals" replace={true} />,
      },

      ...settingsRoutes,
      ...reportingRoutes,
    ],
  },
]
