import { memo, MouseEventHandler, ReactNode } from 'react'
import { Tr, Td } from 'components/tables'
import { Row as TRow, flexRender } from '@tanstack/react-table'
import { WorkerApprovalsAndApprovalDomain } from '../../types'
import { DayCellValue } from '../types'
import { twMerge } from '@lib/tailwind-merge'
import { isSameCell } from '@hooks/useKeyboardForGrid'

interface Props {
  focusedCell: { x: number; y: number }
  row: TRow<WorkerApprovalsAndApprovalDomain>
  index: number
  onApprovalCellClick: (approval: Approval) => void
  onAltCellClick: (cell: Cell) => void
  onCellRightClick: (
    pos: MousePosition,
    cell: Cell,
    options: { approval?: Approval; worker: TWorker },
  ) => void
  selectedApprovals: Approval[]
}

// NOTE: This component is memoized so that we can carefully control re-renders
//       so that we do not re-render every row when the selectedApprovals change.
export const Row = memo<Props>(
  ({
    focusedCell,
    row,
    index: y,
    onApprovalCellClick,
    onAltCellClick,
    onCellRightClick,
    selectedApprovals,
  }) => (
    <Tr virtualized={true} estimatedHeight={64}>
      {
        row
          .getVisibleCells()
          .reduce<{ component: ReactNode[]; numOfNonDateCellsToLeft: number }>(
            (acc, cell, index) => {
              let onLeftClick:
                  | MouseEventHandler<HTMLTableCellElement>
                  | undefined,
                onRightClick
              let selected = false
              const isDateColumn = cell.column.id.startsWith('date')
              const x = index - acc.numOfNonDateCellsToLeft
              const cellPos = isDateColumn
                ? { x, y }
                : { x: Infinity, y: Infinity }

              const focused = isSameCell(focusedCell, cellPos)

              if (isDateColumn) {
                const { approval, worker } = cell.getValue<DayCellValue>()

                onLeftClick = (event) => {
                  if (event.getModifierState('Alt')) {
                    onAltCellClick(cellPos)
                  } else if (approval) {
                    onApprovalCellClick(approval)
                  }
                }

                selected =
                  selectedApprovals.find(({ id }) => approval?.id === id) !==
                  undefined

                onRightClick = (pos: MousePosition) => {
                  onCellRightClick(pos, cellPos, { approval, worker })
                }
              }

              return {
                component: [
                  ...acc.component,
                  <Td
                    className={twMerge(
                      'h-16',
                      isDateColumn && 'p-0',

                      // Scroll margin top/bottom set to height of fixed header/footer
                      focused && 'scroll-mt-[86px] scroll-mb-[63px]',
                    )}
                    key={cell.id}
                    onClick={onLeftClick}
                    onRightClick={onRightClick}
                    selected={selected}
                    focused={focused}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>,
                ],
                // Counting the number of non-date cells to the left of the date column helps get the x position correct
                numOfNonDateCellsToLeft:
                  acc.numOfNonDateCellsToLeft + (isDateColumn ? 0 : 1),
              }
            },
            { component: [], numOfNonDateCellsToLeft: 0 },
          ).component
      }
    </Tr>
  ),
  (oldProps, newProps) => {
    // Default React prop comparison behavior for all props except selectedApprovals
    for (const key of Object.keys(oldProps)) {
      if (key === 'selectedApprovals') continue

      if (
        !Object.is(oldProps[key as keyof Props], newProps[key as keyof Props])
      )
        return false
    }

    // Custom prop comparison behavior for selectedApprovals,
    // consider props unchanged if the old and new props
    // have the same selectedApprovals by ID, regardless of order
    if (
      oldProps.selectedApprovals.length !== newProps.selectedApprovals.length ||
      oldProps.selectedApprovals.some(
        (a) =>
          newProps.selectedApprovals.find((b) => a.id === b.id) === undefined,
      )
    )
      return false

    return true
  },
)

Row.displayName = 'Row'
