import { FC } from 'react'
import { ButtonGroup } from 'components/buttons'
import { addItemsToSet, removeItemsFromSet, useSet } from '@hooks/useSet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import {
  faClockSeven,
  faUtensilsSlash,
} from '@fortawesome/pro-regular-svg-icons'

export enum Anomalies {
  NoBreak = 'NoBreak',
  HasTaggedTime = 'HasTaggedTime',
}

export type AnomalyCounts = {
  [value in Anomalies]: number
}

interface Props {
  className?: string
  counts: AnomalyCounts
  onChange: (selected: Props['selected']) => void
  selected: Set<Anomalies>
}

export const AnomalousTimeCardButtons: FC<Props> = ({
  className,
  counts,
  onChange,
  selected: selectedAnomalies,
}) => {
  const { t } = useTranslation()

  const generateProps = (anomaly: Anomalies) => {
    const selected = selectedAnomalies.has(anomaly)

    const handleClick = () => {
      const performOperationWith = selected ? removeItemsFromSet : addItemsToSet
      const updatedSelected = performOperationWith(anomaly)(selectedAnomalies)
      onChange(updatedSelected)
    }

    return {
      count: counts[anomaly],
      onClick: handleClick,
      selected,
    }
  }

  const buttons = [
    {
      children: (
        <FontAwesomeIcon icon={faUtensilsSlash} size="sm" fixedWidth={true} />
      ),
      tooltip: t('components.anomalousTimeCardButtons.noBreak'),
      ...generateProps(Anomalies.NoBreak),
    },
    {
      children: (
        <FontAwesomeIcon
          icon={faClockSeven}
          size="sm"
          className="text-orange-800"
          fixedWidth={true}
        />
      ),
      tooltip: t('components.anomalousTimeCardButtons.hasTaggedTime'),
      ...generateProps(Anomalies.HasTaggedTime),
    },
  ]

  return <ButtonGroup buttons={buttons} className={className} />
}

export const useFilterRowByIssueType = useSet<Anomalies>
