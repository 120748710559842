import { useQuery } from '@tanstack/react-query'
import { entryKeys } from '@lib/keys'
import { getEntriesByTimeCardId, searchEntries } from '@lib/api'
import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { useWeekDates } from '@hooks/useWeekDates'

interface Options {
  start?: DateTime
  end?: DateTime
  userId: number
}

export const useEntries = (options: Options) => {
  const { start, end } = useWeekDates()

  const startDate = options?.start ?? start
  const endDate = options?.end ?? end

  return useQuery(entryKeys.search(options.userId, startDate, endDate), () =>
    searchEntries(options.userId, startDate, endDate),
  )
}

/**
 * useEntriesByTimeCard and useFetchEntriesByTimeCard (below) differ in how
 * they derive entries.  The former searches, and the latter fetches by
 * time card id. The latter is used within an admin context, while the former
 * is used within the time logging context.
 */
export const useEntriesByTimeCard = (timeCardId: number, userId: number) => {
  const entriesQuery = useEntries({ userId })

  return useMemo(() => {
    if (entriesQuery.data === undefined) return []

    return entriesQuery.data.filter((entry) => entry.timeCard.id === timeCardId)
  }, [entriesQuery.data, timeCardId])
}

export const useFetchEntriesByTimeCard = (timeCardId = 0) => {
  return useQuery(
    entryKeys.byTimeCard(timeCardId),
    () => getEntriesByTimeCardId(timeCardId),
    { enabled: timeCardId !== 0 },
  )
}
