import { FC } from 'react'
import { useNoteForDate } from '@hooks/useNotes'
import { DateTime } from 'luxon'
import { useDateTimeWithLocale } from '@hooks/useDateTimeWithLocale'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'components/loaders'

interface Props {
  date: DateTime
  workdayWorkerId?: number
}

export const TimeLoggingNoteTab: FC<Props> = ({
  date,
  workdayWorkerId = 0,
}) => {
  const { t } = useTranslation()
  const withLocale = useDateTimeWithLocale()
  const { isLoading, note } = useNoteForDate(date, {
    workdayWorkerId,
    enabled: workdayWorkerId !== 0,
  })

  return (
    <div className="flex flex-col px-8 py-4">
      <h2 className="text-neutral-900 font-medium mb-2">
        {withLocale(date).toLocaleString({
          weekday: 'long',
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}
      </h2>

      {isLoading ? (
        <Spinner />
      ) : note ? (
        <>
          <h3 className="text-xs font-medium text-neutral-500 my-2">
            {t('features.timeLogging.note')}
          </h3>
          <pre className="font-sans text-sm text-neutral-800">{note.body}</pre>
        </>
      ) : (
        <div className="font-medium text-neutral-500 text-xs">
          {t('features.employeeSidebar.noNotesForWorkerOnThisDate')}
        </div>
      )}
    </div>
  )
}
