import { getApprovalEventLog } from '@lib/api'
import { approvalEventLogKeys } from '@lib/keys'
import { useQuery } from '@tanstack/react-query'

export const useApprovalEventLog = (approvalId = 0) => {
  return useQuery(
    approvalEventLogKeys.get(approvalId),
    () => getApprovalEventLog(approvalId),
    { enabled: approvalId !== 0 },
  )
}
